/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
import React, { Component } from 'react';
import AddIndicator from '@atlaskit/icon/glyph/add';
import CollapseIndicator from '@atlaskit/icon/glyph/chevron-down';
import ExpandIndicator from '@atlaskit/icon/glyph/chevron-right';
import { colors } from '@atlaskit/theme';
import { type IntlShape, defineMessages, injectIntl } from '@atlassian/jira-intl';
import { withTheme } from '../../../../../../../../app/context/theme-context';
import { childrenOperationIndicatorWidth } from '../../../../../../../../constants';
import type { RowExpandCallback, RowAddCallback } from '../../../../../../../../model/callbacks';
import type { Optional } from '../../../../../../../../model/optional';
import type { RowId } from '../../../../../../../../model/rows';
import type { CompiledTheme } from '../../../../../../../../model/themes';

const messages = defineMessages({
	collapseIcon: {
		id: 'virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.collapse-icon',

		defaultMessage: 'Click to collapse row',
		description: '',
	},
	expandIcon: {
		id: 'virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.expand-icon',

		defaultMessage: 'Click to expand row',
		description: '',
	},
	addIcon: {
		id: 'virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.add-icon',

		defaultMessage: 'Click to add row',
		description: '',
	},
});

const baseIndicatorStyles = {
	display: 'flex',
	flex: '0 0 auto',
	justifyContent: 'center',
	alignItems: 'center',
	color: colors.N70,
} as const;

const interactiveIndicatorStyles = {
	...baseIndicatorStyles,
	width: `${childrenOperationIndicatorWidth}px`,
	cursor: 'pointer',
} as const;

const getDefaultIndicatorStyles = (isMaxDepth: boolean, theme: CompiledTheme) => ({
	...baseIndicatorStyles,
	width: `${
		isMaxDepth ? theme.row.maxDepthChildrenOperationIndicatorWidth : childrenOperationIndicatorWidth
	}px`,
});

const getAddIndicatorWrapperStyles = (isRowHovered: boolean) => ({
	height: '16px',
	width: '16px',
	visibility: isRowHovered ? 'visible' : 'hidden',
});

type Props = {
	rowId: RowId;
	rowDepth: number;
	maxDepth: number;
	isRowExpanded: boolean;
	isRowHovered: boolean;
	isRowKeyboardActive: boolean;
	rowHasChildren: boolean;
	isAddEnabled: boolean;
	onRowExpansionChangeRequested: RowExpandCallback;
	onRowAddRequested: Optional<RowAddCallback>;
	intl: IntlShape;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
export class RowChildOperationIndicator extends Component<Props> {
	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { rowHasChildren, isRowExpanded } = this.props;

		/* A row that had children but now has none SHOULD NOT be expanded.
		 * The general use case for this is cancelling an add operation, where the
		 * temporary row was the only child. As cancelling can occur from internal or
		 * external to the table, this is the only way to ensure this behaviour.
		 */
		if (rowHasChildren && !nextProps.rowHasChildren && isRowExpanded) {
			this.onCollapse();
		}
	}

	shouldComponentUpdate(nextProps: Props) {
		const {
			rowId,
			rowDepth,
			maxDepth,
			isRowExpanded,
			isRowHovered,
			isRowKeyboardActive,
			rowHasChildren,
			isAddEnabled,
		} = this.props;

		return (
			rowId !== nextProps.rowId ||
			rowDepth !== nextProps.rowDepth ||
			maxDepth !== nextProps.maxDepth ||
			isRowExpanded !== nextProps.isRowExpanded ||
			isRowHovered !== nextProps.isRowHovered ||
			isRowKeyboardActive !== nextProps.isRowKeyboardActive ||
			rowHasChildren !== nextProps.rowHasChildren ||
			isAddEnabled !== nextProps.isAddEnabled
		);
	}

	onExpand = () => {
		const { rowId, onRowExpansionChangeRequested } = this.props;

		onRowExpansionChangeRequested({
			rowId,
			shouldExpand: true,
		});
	};

	onCollapse = () => {
		const { rowId, onRowExpansionChangeRequested } = this.props;

		onRowExpansionChangeRequested({
			rowId,
			shouldExpand: false,
		});
	};

	onRowAddRequested = () => {
		const { rowId, onRowAddRequested } = this.props;

		onRowAddRequested &&
			onRowAddRequested({
				anchorId: rowId,
				position: 'INSIDE',
			});
	};

	renderCollapseIndicator() {
		const {
			intl: { formatMessage },
			rowId,
		} = this.props;

		return (
			<div
				style={interactiveIndicatorStyles}
				onClick={this.onCollapse}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.collapse-${rowId}`}
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && this.onCollapse}
				role="button"
				tabIndex={0}
			>
				<CollapseIndicator size="small" label={formatMessage(messages.collapseIcon)} />
			</div>
		);
	}

	renderExpandIndicator() {
		const {
			intl: { formatMessage },
			rowId,
		} = this.props;

		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div
				style={interactiveIndicatorStyles}
				onClick={this.onExpand}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.expand-${rowId}`}
			>
				<ExpandIndicator size="small" label={formatMessage(messages.expandIcon)} />
			</div>
		);
	}

	renderAddIndicator() {
		const {
			intl: { formatMessage },
			isRowHovered,
			rowId,
		} = this.props;

		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div
				style={interactiveIndicatorStyles}
				onClick={this.onRowAddRequested}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.default-row.content.child-operation-indicator.add-${rowId}`}
			>
				{/* @ts-expect-error - TS2322 - Type '{ height: string; width: string; visibility: string; }' is not assignable to type 'Properties<string | number, string & {}>'. */}
				<div style={getAddIndicatorWrapperStyles(isRowHovered)}>
					<AddIndicator size="small" label={formatMessage(messages.addIcon)} />
				</div>
			</div>
		);
	}

	render() {
		const { maxDepth, rowDepth, rowHasChildren, isRowExpanded, isAddEnabled, theme } = this.props;

		const isMaxDepth = rowDepth === maxDepth;

		if (!isMaxDepth) {
			if (rowHasChildren) {
				if (isRowExpanded) {
					return this.renderCollapseIndicator();
				}
				return this.renderExpandIndicator();
			}
			if (isAddEnabled) {
				return this.renderAddIndicator();
			}
		}

		return <div style={getDefaultIndicatorStyles(isMaxDepth, theme)} />;
	}
}

export default injectIntl(withTheme(RowChildOperationIndicator));
